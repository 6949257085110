import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="container pt-5">
      <h1 className="mb-5">About</h1>
      <p class="mb-5 lead">Toonify is a set of face transformation tools created by <a href="https://www.justinpinkney.com">Justin Pinkney</a> and <a href="https://linktr.ee/Norod78">Doron Adler</a>.</p>
      
      <h4 className="mb-3">Toonify Classic is kindly supported by:</h4>
      <ul className="mb-5">
        <li>
          <p>Our generous supporters on <a href='https://ko-fi.com/W7W228W2S'>Ko-fi!</a></p> 
        </li>
        <li>
          <p><a href="https://deepai.org">DeepAI</a>: an <a href="https://deepai.org/machine-learning-glossary-and-terms/artificial-intelligence">artificial intelligence</a> company accelerating the world's transition to A.I</p>
        </li>
      </ul>
      
      <h4 className="mb-3">Contact Us</h4>
      <p className="mb-5">If you've got any questions, problems, or enquiries get in touch at <a href="mailto:help@toonify.photos">help@toonify.photos</a>.</p>

      <Link to="/">Go back to the homepage</Link>

    </div>
  </Layout>
)

export default SecondPage
